.NewScan form {
  padding-bottom: 15px;
}

.NewScan form textarea {
  height: 300px;
  font-size: 24px;
}

.NewScan form text {
  height: 30px;
  font-size: 24px;
}
